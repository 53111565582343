import { AwsInstance } from '../../auth/hooks/awsManager';
import {
  BulkUploadRequest,
  BulkUploadResponse,
  BulkUploadStatusRequest,
  BulkUploadStatusResponse,
  UploadStatus,
} from '../../shared/BulkUpload/types/bulkUploadTypes';

const apiUrl = process.env.REACT_APP_REST_URL;

export const callGetUploadUrl = async (
  request: BulkUploadRequest
): Promise<BulkUploadResponse> => {
  const { entity_type } = request;

  if (!entity_type) {
    throw new Error('entity_type is required');
  }

  const response: Response = await fetch(`${apiUrl}/uploads/`, {
    method: 'POST',
    headers: {
      Authorization: AwsInstance.getInstance().getToken(),
      'Content-Type': 'application/json',
    } as HeadersInit,
    body: JSON.stringify(request),
  });

  if (!response.ok) {
    const error: Error = await response.json();
    throw new Error(error.message);
  }

  return (await response.json()) as BulkUploadResponse;
};

export const UploadToS3 = async (
  file: File,
  url: string,
  fields: { [key: string]: string }
): Promise<Response> => {
  const formData = new FormData();

  formData.append('x-amz-credential', fields['x-amz-credential']);
  formData.append('key', fields['key']);
  formData.append('policy', fields['policy']);
  formData.append('x-amz-signature', fields['x-amz-signature']);
  formData.append('x-amz-security-token', fields['x-amz-security-token']);
  formData.append('x-amz-algorithm', fields['x-amz-algorithm']);
  formData.append('x-amz-date', fields['x-amz-date']);
  formData.append('file', file);

  return fetch(url, {
    method: 'POST',
    body: formData,
  });
};

export const callGetUploadStatus = async (
  request: BulkUploadStatusRequest
): Promise<UploadStatus> => {
  const { correlation_id, page_size, page_number, row_status } = request;

  if (!correlation_id) {
    throw new Error('The correlation_id is not provided');
  }

  const response: Response = await fetch(
    `${apiUrl}/uploads/status/${correlation_id}${
      page_size ? `?page_size=${page_size}` : ''
    }${page_number ? `&page_number=${page_number}` : ''}${
      row_status ? `&row_status=${row_status}` : ''
    }${row_status ? `&row_status=${row_status}` : ''}`,
    {
      method: 'GET',
      headers: {
        Authorization: AwsInstance.getInstance().getToken(),
        'Content-Type': 'application/json',
      } as HeadersInit,
    }
  );

  if (!response.ok) {
    const error: Error = await response.json();
    throw new Error(error.message);
  }

  const body = (await response.json()) as BulkUploadStatusResponse;

  return {
    httpStatus: response.status,
    body: body,
  } as UploadStatus;
};
